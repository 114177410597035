const lang = { 
    "pt-BR": {
      profile: 'Meu Perfil',
      erroCredenciais:
      "Algo deu errado! Verifique as credenciais e tente novamente.",
      copyright: "© 2024 Pluxee. Todos os direitos reservados.",
      ingles: "Inglês",
      portugues: "Português",
      espanhol: "Espanhol",
      login: "Faça o login para continuar",
      entrar: "Entrar",
      senha: "Senha",
      lembrarMe: "Lembrar-me",
      sair: "Sair",
      buscar: "Buscar",
      buscarRota: "Buscar por rota",
      buscarRaio: "Buscar por raio",
      buscarBairro: "Buscar por bairro",
      buscarRede: "Buscar por rede/cidade",
      buscarEndereço: "Buscar por endereço",
      limparFiltros: "Limpar filtros",
      rota: "Rota",
      raio: "Raio",
      bairro: "Bairro",
      estabelecimento: "Estabelecimento",
      endereço: "Endereço",
      filtrar: "Filtrar",
      urbana: "Urbana",
      rodoviaria: "Rodoviária",
      evitarPedagio: "Evitar pedágios",
      evitarBalsa: "Evitar balsa",
      evitarRodovia: "Evitar rodovias",
      digiteLocal: "Digite um local",
      adicionarDestino: "Adicionar um destino",
      digiteEndereço: "Digite um endereço",
      digiteBairro: "Digite um bairro",
      estado: "Estado",
      municipio: "Município",
      rede: "Rede",
      postos: "Postos",
      serviços: "Serviços",
      atividades: "Atividades",
      preferencial: "Preferencial",
      favoritos: "Favoritos",
      filtroPostos: "Filtro de Postos",
      filtroServiços: "Filtro de Serviços",
      filtroAtividades: "Filtro de Atividades",
      filtroPreferencial: "Filtro Preferencial",
      filtroFavoritos: "Filtro de Favoritos",
      dadosEstabelecimento: "Dados do estabelecimento",
      dados: "Dados",
      especialidade: "Especialidade",
      fotos: "Fotos",
      preços: "Preços",
      telefone: "Telefone",
      corrigirLocalização: "Corrigir localização",
      novaRota: "Criar uma nova rota até o destino",
      combustivel: "Combustível",
      atualização: "Atualização",
      concluir: "Concluir",
      estabelecimentosEncontrador: "estabelecimentos encontrados",
      imprimirLista: "Imprimir lista",
      selecionarImpressão: "Selecionar estabelecimentos para impressão",
      cidade: "Cidade",
      logradouro: "Logradouro",
      sentidoVia: "Sentido da via",
      listaEstabelecimentos: "Lista estabelecimentos",
      tipoRede: "Tipo de rede",
      pontoPartida: "Ponto de partida",
      informeDestino: "Informe o destino",
      selecionarCombustivel: "Selecione até 3 combustíveis",
      opcoesFiltro: "Digite cidade/estabelecimento/ramo",
      estabelecimentosEncontrados: "estabelecimentos encontrados",
      valoresReferencia: "Valores de referência sujeito a alteração",
      dataAtualização: "Data de atualização",
      preImpressao: "Pré impressão",
      bandeiras: "Bandeiras",
      limpar: "Limpar",
      novoEndereço: "Novo endereço",
      numero: "Número",
      motivo: "Motivo",
      cadastroIncompleto: "Cadastro incompleto",
      localizaçãoIncorreta: "Localização incorreta",
      outros: "Outros",
      enviar: "Enviar",
      descricao: "Descrição",
      selecionadosImpressao: "Estabelecimentos selecionados para impressão",
      estabelecimentosSelecionados: "estabelecimentos selecionados",
      salvarRota: "Salvar rota",
      apagarRota: "Deseja apagar esta rota salva?",
      nomeRota: "Informe o nome da rota",
      rotasSalvas: "Rotas salvas",
      listaEndereços: "Lista de endereços",
      sim: "Sim",
      não: "Não",
      selecione: "Selecione",
      salvar: "Salvar",
    },
    
    
    en: {
      profile: 'My Profile',
      erroCredenciais: "Something it´s wrong. Verify your data",
      copyright: "© 2024 Pluxee. Todos os direitos reservados.",
      ingles: "English",
      portugues: "Português",
      espanhol: "Spanish",
      login: "Please login to continue",
      entrar: "Entrar",
      senha: "Log in",
      lembrarMe: "Remind me",
      sair: "Out",
      buscar: "Search",
      buscarRota: "Search by route",
      buscarRaio: "Search by radius",
      buscarBairro: "Search by neighborhood",
      buscarRede: "Search by network/city",
      buscarEndereço: "Search by address",
      limparFiltros: "Clear filters",
      rota: "Route",
      raio: "Radius",
      bairro: "Neighborhood",
      estabelecimento: "Establishment",
      endereço: "Address",
      filtrar: "Filter",
      urbana: "Urban",
      rodoviaria: "Road",
      evitarPedagio: "Avoid tolls",
      evitarBalsa: "Avoid ferry",
      evitarRodovia: "Avoid highways",
      digiteLocal: "Avoid highways",
      adicionarDestino: "Add a location",
      digiteEndereço: "Enter an address",
      digiteBairro: "Enter a neighborhood",
      estado: "State",
      municipio: "County",
      rede: "Network",
      postos: "Stations",
      serviços: "Services",
      atividades: "Activities",
      preferencial: "Preferential",
      favoritos: "Favorites",
      filtroPostos: "Station Filter",
      filtroServiços: "Service Filter",
      filtroAtividades: "Activity Filter",
      filtroPreferencial: "Preferred Filter",
      filtroFavoritos: "Favorites Filter",
      dadosEstabelecimento: "Establishment details",
      dados: "Data",
      especialidade: "Specialty",
      fotos: "Photos",
      preços: "Prices",
      telefone: "Telephone",
      corrigirLocalização: "Fix location",
      novaRota: "Create a new route to the destination",
      combustivel: "Fuel",
      atualização: "Update",
      concluir: "Conclude",
      estabelecimentosEncontrador: "Establishments found",
      imprimirLista: "Print list",
      selecionarImpressão: "Select establishments for printing",
      cidade: "City",
      logradouro: "Public place",
      sentidoVia: "Direction of the road",
      listaEstabelecimentos: "List establishments",
      tipoRede: "Network type",
      pontoPartida: "Starting point",
      informeDestino: "Inform the destination",
      selecionarCombustivel: "Select up to 3 fuels",
      opcoesFiltro: "Enter city/establishment/branch",
      estabelecimentosEncontrados: "establishments found",
      valoresReferencia: "Reference values ​​subject to change",
      dataAtualização: "Update date",
      preImpressao: "Preprint",
      bandeiras: "Brands",
      limpar: "Clear",
      novoEndereço: "New address",
      numero: "Number",
      motivo: "Reason",
      cadastroIncompleto: "Incomplete registration",
      localizaçãoIncorreta: "Incorrect location",
      outros: "Others",
      enviar: "Enviar",
      descricao: "Description",
      selecionadosImpressao: "Establishments selected for printing",
      estabelecimentosSelecionados: "selected establishments",
      salvarRota: "Save route",
      apagarRota: "Do you want to delete this saved route?",
      nomeRota: "Enter the route name",
      rotasSalvas: "saved routes",
      listaEndereços: "Address list",
      sim: "Yes",
      não: "No",
      selecione: "Select",
      salvar: "Save",
      
    },
    
    
    es: {
      profile: 'Mi Perfil',
      erroCredenciais: "Algo está mal. Verifica tus datos",
      copyright: "© 2024 Pluxee. Todos os direitos reservados.",
      ingles: "Inglés",
      portugues: "Portugués",
      espanhol: "Español",
      login: "Por favor inicie sesión para continuar",
      entrar: "Iniciar sesión",
      senha: "Clave",
      lembrarMe: "Recuerdame",
      sair: "Salir",
      buscar: "Buscar",
      buscarRota: "Buscar por ruta",
      buscarRaio: "Buscar por radio",
      buscarBairro: "Buscar por barrio",
      buscarRede: "Buscar por red/ciudad",
      buscarEndereço: "Buscar por dirección",
      limparFiltros: "Filtros claros",
      rota: "Ruta",
      raio: "Radio",
      bairro: "Barrio",
      estabelecimento: "Establecimiento",
      endereço: "Dirección",
      filtrar: "Filtrar",
      urbana: "Urbano",
      rodoviaria: "Carretera",
      evitarPedagio: "Evite los peajes",
      evitarBalsa: "Evite transbordador",
      evitarRodovia: "Evite autopistas",
      digiteLocal: "Introduce una ubicación",
      adicionarDestino: "Agrega un lugar",
      digiteEndereço: "Introduce una direccion",
      digiteBairro: "Entrar en un barrio",
      estado: "Estado",
      municipio: "Condado",
      rede: "La red",
      postos: "Estaciones",
      serviços: "Servicios",
      atividades: "Actividades",
      preferencial: "Preferente",
      favoritos: "Favoritos",
      filtroPostos: "Filtro de Estación",
      filtroServiços: "Filtro de Servicio",
      filtroAtividades: "Filtro Atividades",
      filtroPreferencial: "Filtro Preferido",
      filtroFavoritos: "Filtro de Favoritos",
      dadosEstabelecimento: "Detalles del establecimiento",
      dados: "Dados",
      especialidade: "Especialidad",
      fotos: "Fotos",
      preços: "Precios",
      telefone: "Teléfono",
      corrigirLocalização: "Arreglar ubicación",
      novaRota: "Crear una nueva ruta al destino",
      combustivel: "Combustible",
      atualização: "Actualizar",
      concluir: "Concluir",
      estabelecimentosEncontrador: "Establecimientos encontrados",
      imprimirLista: "Imprimir lista",
      selecionarImpressão: "Seleccionar establecimientos para imprimir",
      cidade: "Ciudad",
      logradouro: "Lugar público",
      sentidoVia: "Dirección de la carretera",
      listaEstabelecimentos: "Lista de establecimientos",
      tipoRede: "Tipo de red",
      pontoPartida: "Punto de partida",
      informeDestino: "Informar el destino",
      selecionarCombustivel: "Seleccione hasta 3 combustibles",
      opcoesFiltro: "Introducir ciudad/establecimiento/sucursal",
      estabelecimentosEncontrados: "establecimientos encontrados",
      valoresReferencia: "Valores de referencia sujetos a cambios",
      dataAtualização: "Fecha de actualización",
      preImpressao: "Preimpresión",
      bandeiras: "Banderas",
      limpar: "Limpio",
      novoEndereço: "Nueva dirección",
      numero: "Número",
      motivo: "Razón",
      cadastroIncompleto: "Registro incompleto",
      localizaçãoIncorreta: "Ubicación incorrecta",
      outros: "Otros",
      enviar: "Enviar",
      descricao: "Descripción",
      selecionadosImpressao: "Establecimientos seleccionados para la impresión",
      estabelecimentosSelecionados: "establecimientos encontrados",
      salvarRota: "Guardar ruta",
      apagarRota: "¿Quieres eliminar esta ruta guardada?",
      nomeRota: "Ingrese el nombre de la ruta",
      rotasSalvas: "Rutas guardadas",
      listaEndereços: "Lista de direcciones",
      sim: "Sí",
      não: "No",
      selecione: "Seleccione",
      salvar: "Guardar",
    }, 
  };
  
  export default lang;
  