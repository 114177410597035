import styled, { keyframes } from "styled-components";
import { lighten, shade } from "polished";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ContentEstabelecimento = styled.div`
  > div {
    overflow-y: scroll;
    overflow-x: scroll;
    /* height: ${(props) => (props.type === 'print' ? "400px!important" : '300px' )}; */
    height: 300px;
    
    @media (max-height: 500px) {
      height: 140px;
    }
    
    ::-webkit-scrollbar-track {
      background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 9px;
      height: 3px ;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      /* border-radius: 5px; */
      background: #221C46;
    }
  }
  
  .spinner {
    height: 26px!important;
    width: 26px!important;
  }
  .spinner.spinner-primary {
    border-left-color: #221c46;
  }
  
  .container {
    @media (min-width: 700px) {
      margin-top: 45px;
    };
    margin-left: 0
  }
  
  > button {
    float: right;
    margin: 10px 10px 10px 0px;
    /* background-color: #931a28;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    
    :hover {
    background-color: ${(props) =>
      shade(0.3, '#931a28')} !important;
  }

  :active {
    outline: none;
    background-color: ${(props) =>
      lighten(0.3, '#931a28')} !important;
    box-shadow: none;
  }

  :focus {
    outline: none;
  }
  
  svg {
    height: 18px;
    width: 18px;
    animation: ${rotate} 2s linear infinite;
    color: #fff;
  } */
  }
  
  header {
    height: 50px;
    background-color: #221C46;
    /* border-radius: 10px 10px 0 0; */
    display: flex;
    /* padding: 0 15px; */
    align-content: center;
    align-items: center;
    
    .close {
      margin-left: auto;
      cursor:pointer;
    }
    
    img, svg {
      width: 21px;
      height: 21px;
      color: #fff;
      margin: 15px;
    }
    
    p {
      text-transform: uppercase;
      color: #fff;
      font-weight: 500;
    }
  }
  
  nav {
    padding: 0 15px;
    margin-top: 10px;
    
    button {
      /* background-color: #931a28;
      color: #fff;
      padding: 5px 10px;
      border-radius: 10px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600; */
      /* height: 38px; */
      /* text-align: center; */
    }
    
    input {
      font-family: "Open Sans",sans-serif;
    }
    
    .hideInput {
      input {
        width: 0px;
      }
      .chip {
        padding: 3px 10px;
        margin-bottom: 0px;
        border-radius: 0px;
      }
    }
    .showInput {
      input {
        width: 250px;
      }
    }
    
    .multiSelectContainer {
      border-color: #dee2e6;
      border-width: thin
    }
    .multiSelectContainer:focus-within {
      border-color: #221c46;
      border-width: thin
      
    }
    .chip, .highlightOption, .multiSelectContainer li:hover {
      background-color: #221c46
    }
    .searchWrapper {
      border: none;
      height: 40px;
      overflow-x: scroll;
      white-space: nowrap;
      border-radius: 0px;
      min-height: 38px;
      
      ::-webkit-scrollbar-track {
        background-color: #f4f4f4;
      }
      ::-webkit-scrollbar {
        background: #f4f4f4;
        height: 5px;              
        width: 0;
      }
      ::-webkit-scrollbar-thumb {
        /* border-radius: 5px; */
        background: #221C46;
      }
    }
  
    }
    .searchWrapper ::placeholder {
      color: #858585
    }
    
  
  section {
    display: grid;
    grid-template-columns: ${(props) => (props.columns === 4 && "230px 97px 97px 97px 97px 50px")};
    grid-template-columns: ${(props) => (props.columns === 3 && "230px 97px 97px 97px 50px")};
    grid-template-columns: ${(props) => (props.columns === 2 && "230px 97px 97px 50px")};
    border-bottom: 1px solid #d7d7d7;
    background: #fff;
    padding: 10px;
    z-index: 1;
    
    @media (min-width: 768px) {
      position:fixed;
    }
  }
    
    p {
      color: #221C46;
      /* font-size: 12px; */
    }
    
    li {
      display: grid;
      grid-template-columns: ${(props) => (props.columns === 4 && "97px 97px 97px 99px 56px")};
      grid-template-columns: ${(props) => (props.columns === 3 && "97px 97px 97px 56px")};
      grid-template-columns: ${(props) => (props.columns === 2 && "97px 97px 56px")};
      /* gap: 10px; */
      /* justify-items: center; */
      font-size: 9px;
      align-self: center;
    }
    
    ul {
      /* justify-items: center;
      align-self: center; */
      
      p {
        /* margin-top: 2px */
      text-transform: uppercase;
        
      }
    }
    
    svg {
      width: 25px;
      height: 25px;
    }
  }
  
  main {
    display: grid;
    grid-template-columns: ${(props) => (props.columns === 4 && "30px 200px 97px 97px 97px 97px 56px")};
    grid-template-columns: ${(props) => (props.columns === 3 && "30px 200px 97px 97px 97px 56px")};
    grid-template-columns: ${(props) => (props.columns === 2 && "30px 200px 97px 97px 56px")};
    /* gap: 10px; */
    align-items: center;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 3px;
    
    h1 {
      font-size: 11px;
      color: #221C46;
      margin-left: 10px;
      line-height: 15px;
      padding: 5px;
    }
    
    footer {
      line-height: unset;
      text-align: right;
      margin-right: 5px!important;
      color: #FF7375;
    }
    
    span {
      font-weight: 800;
      margin-bottom: 3px;
      margin-top: 12px;
      /* margin-left: -5px; */
    }
    
    svg, img {
      justify-self: center;
      width: 28px;
      height: 28px;
      /* border-radius: 15px; */
      padding: 2px;
      color: #fff;
    }
    
    div {
      text-align: center;
      border-left: 1px solid #d7d7d7;
      /* border-right: 1px solid #d7d7d7; */
      height: 45px;
      width: 100%;
      padding: 0 5px;
      font-size: 13px;
      color: #221C46;
    }
    
    h4 {
      font-weight: 700;
      line-height: 24px;
      color: #221C46
    }
    
    h5 {
      font-size: 11px;
      line-height: none;
      color: #686868;
    }
  }
  
  h6 {
    float: left;
    padding: 9px 10px;
    font-size: 10px;
    color: #221C46;
    line-height: 20px;
  }
`;

export const ContentImprimir = styled.div`
  top: 60px;
  position: absolute;
  width: 70vw;
  height: 85vh;
  background: #fff;
  z-index: 9998;
  box-shadow: 1px 5px 6px #666;
  /* border-radius: 10px; */
  
  > div {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 55vh;
    
      
    ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 8px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      /* border-radius: 5px; */
      background: #221C46;
    }
  }
  
  > button {
    float: right;
    margin: 15px 20px;
    /* background-color: #931a28;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600; */
  }
  
  header {
    height: 50px;
    background-color: #221C46;
    /* border-radius: 10px 10px 0 0 ; */
    display: flex;
    /* padding: 0 15px; */
    align-content: center;
    align-items: center;
    
    .close {
      margin-left: auto;
      cursor:pointer;
    }
    
    svg {
      width: 21px;
      height: 21px;
      color: #fff;
      margin: 15px;
    }
    
    p {
      text-transform: uppercase;
      color: #fff;
      font-weight: 500;
    }
  }
  
  section {
    display:grid;
    grid-template-columns: 50% 22% 22%;
    gap: 2%;
    margin: 15px;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 5px;
    
    p {
      width: 270px;
      color: #221C46;
      font-size: 15px;
      align-self: center;
      width: 100%;
    }
  }
  
  main {
    text-align: -webkit-center;
    margin: 15px;
  }
  
  table {
    
    font-size: 13px;
    color: #221C46;
    
    svg {
      width: 28px;
      height: 28px;
      /* border-radius: 15px; */
      padding: 4px;
      color: #fff;
    }
  }
  
  tbody > tr:nth-of-type(odd) {
    background-color: #f9f8f8;
  }
  
  
  tr {
    > :nth-child(1) {
      width: 5%;
    }
    > :nth-child(2) {
      width: 16%;
    }
    > :nth-child(3) {
      width: 12%;
    }
    > :nth-child(4) {
      width: 13%;
    }
    > :nth-child(5) {
      width: 15%;
    }
    > :nth-child(6) {
      width: 12%;
    }
    > :nth-child(7) {
      width: 13%;
    }
    > :nth-child(8) {
      width: 10%;
    }
    > :nth-child(9) {
      width: 4%;
    }
  }
  
  td:nth-child(8) {
    color: #931a28;
  }
    
  td, th {
    padding: 0.5%;
  }

`;

export const Checkbox = styled.div`
  /* margin: ${(props) => (!props.all ? "-13px" : '15px 0' )}; */
  margin-top: ${(props) => (props.all ? "-13px" : '15px 0' )};
  
  label {
  /* display: block; */
  position: relative;
  /* padding-left: 25px;
  margin-bottom: 12px; */
  width: 18px;
  cursor: pointer;
  font-size: 13px;
  color: #221C46;
}

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

span {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #221C46;
  /* border-radius: 5px; */
  margin-top: 12px;
  margin-left: -5px;
}

label:hover input ~ span {
  background-color: #f6f6f6;
}

label input:checked ~ span {
  background-color: #FF7375;
}

span:after {
  content: "";
  position: absolute;
  display: none;
}

label input:checked ~ span:after {
  display: block;
}

label span:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;

export const Modal = styled.div`
.tooltipRoute {
  padding: 5px!important;
  opacity: 1!important;
  /* border-radius: 10px; */
  width: 45%!important;
  background-color: #221C46 ;
  
  
  @media (max-width: 768px) {
     width: 70%!important;
  }
    
  div {
    display: grid;
    grid-template-columns: 70% 28%;
    gap: 2%;
  }
  
  button {
    /* background-color: #931a28;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600; */
    /* height: 38px */
  }
}

.flexible-modal {
    /* border-radius: 10px; */
    z-index: 999;
    right: 60px;
    width: ${(props) => (props.columns === 4 && "700px!important")};
    width: ${(props) => (props.columns === 3 && "603px!important")};
    width: ${(props) => (props.columns === 2 && "506px!important")};
    height: 515px!important;
    
    @media (max-width: 768px) {
      width: 90%!important;
      right: 5%;
      height: 560px!important;
      top: 130px!important;;
    }
    
    @media (max-height: 450px) {
      height: 340px!important;
    }
  
  }
  
  .flexible-modal-drag-area {
    background: transparent; 
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 90%!important;
    cursor: move;
    right: auto!important;
  }
  
  .flexible-modal-drag-area-left{
      position:absolute;
      left:0;
      bottom:0;
      cursor:move;
    }

    .flexible-modal-drag-area-right{
      height: 50px;
      position:absolute;
      right:0;
      bottom:0;
      cursor:move;
    }

    .flexible-modal-drag-area-bottom{
      height: 50px;
      position:absolute;
      right:0;
      bottom:0;
      cursor:move;
      width: 60%!important;
      right: auto;
      
      @media (max-width: 500px) {
        width: 0!important;
      }
    }
`;


   
