import React, { forwardRef } from "react";
import { Button, Spinner } from '@dilicorp/ui';
import { Container } from "./styles";

const ButtonModal = forwardRef(({ loading = false, label, ...rest }, ref) => {

    return (
      <Container>
        <Button  {...rest} disabled={loading} ref={ref} style={{width: '100%', marginTop: 20}}>
          {loading ? <Spinner /> : <p>{label}</p>}
        </Button>
      </Container>
    );
  }
);

export default ButtonModal;

