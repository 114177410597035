import React, { useEffect, useState, useCallback }  from "react";
import { useGlobal, setGlobal } from 'reactn';
import { MdAddBusiness } from 'react-icons/md'
import { FaTimes } from 'react-icons/fa'
import Multiselect from 'multiselect-react-dropdown';
import InputText from '../../components/InputDefault';
import LoaderContent from "../../components/Spinner";
import { format } from "date-fns";
import AuthContext from "./../../context/auth";
import { Spinner } from '@dilicorp/ui';
import { FormattedMessage, injectIntl } from "react-intl";
import { ContentEstabelecimento, Checkbox, Modal} from "./styles";
import api from "../../services/api";
import Tooltip from "../Tooltip";
import ReactModal from 'react-modal-resizable-draggable';
import { toast } from "react-toastify";
import iconLista from "./../../assets/lista.png";
import { Button } from '@dilicorp/ui'; 
import iconBomba from "./../../assets/bomba-branco.png";


setGlobal({});

const ModalPrint = ({search, intl}) => {
  
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  
  //MODAL
  const [modalEstabelecimentos, setModalEstabelecimentos] = useGlobal('modalEstabelecimento');
  const [modalImprimir, setModalImprimir] = useState(false);
  
  //LIST
  const [listEstablishments, setListEstablishments] = useGlobal('listEstablishment');
  
  //STATES
  const [nameRoute, setNameRoute] = useState();
  const [routes, setRoutes] = useGlobal('savedRoutes');
  
  const [fuelsSelected, setFuelsSelected] = useState([]);
  const [filter, setFilter] = useState("");
  const [columns, setColumns] = useState(4);
  const [fuelsColumns, setFuelsColumns] = useState([]);
  const [indexValue, setIndexValue] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  
  //OPTIONS
  const [optionsFuels, setOptionsFuels] = useState([]);
  
  // LABELS
  const selectPlaceholder = intl.formatMessage({ id: "selecionarCombustivel" });
  const optionsPlaceholder = intl.formatMessage({ id: "opcoesFiltro" });
  const nameRoutePlaceholder = intl.formatMessage({ id: "nomeRota" });
  
  
  const onSelect = useCallback((selectedList, selectedItem) => {
    setFuelsSelected([
      ...fuelsSelected,
      selectedItem
    ])
  }, [fuelsSelected])
  
  const onRemove = useCallback((selectedList, removedItem) => {
    var fuelsFilter = []
    fuelsFilter = fuelsSelected?.filter(
      (fuel) => fuel !== removedItem
    );
    
    setFuelsSelected(fuelsFilter)
  }, [fuelsSelected])
  
  const handleFound = useCallback(() => {
    var count = 0
    listEstablishments?.forEach((establishments) => {
      if (establishments.show && establishments.brandSelect && establishments.showPrice && establishments.showAllFavorites) {
        count = count + 1
      }
    })
    return count
  }, [listEstablishments])
  
  const loadFuels = useCallback(async () => {
    try {
      const { data: response } = await api.get(`products`);
      
      const formattedFuels = response.data.map((fuel) => {
        const obj = {};

        Object.assign(obj, {
          value: fuel.id,
          label: fuel.name,
        });

        return obj;
      });

      setOptionsFuels(formattedFuels)
    } catch (error) {
      console.log(error)
    }
  }, []);
  
  const handleShowFuel = useCallback(async (item) => {
    listEstablishments[0].valores.forEach((fuel, index) => {
      
      if (item?.value === fuel?.id) {
        setIndexValue(index);
      }
    })
    localStorage.setItem("showFuelStorage",JSON.stringify(item));
  }, [listEstablishments]);
  
  const handleFilter = useCallback(async(param) => {
    // VALIDANDO COMBUSTÍVEIS SELECIONADOS
    var fuels = [];
    
    var fuelsStorage = localStorage.getItem("fuelsStorage");
    var foundStorage = localStorage.getItem("foundStorage");
    var showFuelStorage = localStorage.getItem("showFuelStorage");
    
    // CASO NÃO SEJA FEITA UMA NOVA PESQUISA, O COMBUSTÍVEL EXTRA É EXIBIDO
    if (parseInt(foundStorage) === handleFound()) {
      var fuelStorage = JSON.parse(showFuelStorage);
      handleShowFuel(fuelStorage);
    } else {
      localStorage.removeItem("showFuelStorage");
    }
    
    
    // CARREGAR LISTAGEM DE COMBUSTÍVEIS EXIBIDOS NA TELA
    if (fuelsStorage && param !== 'filter'){
      fuels = JSON.parse(fuelsStorage);
    } else {
      
      if (fuelsSelected.length < 1){
        
        const { data: response } = await api.get(`products?common=${true}`);
        
        fuels = [response.data[0].name, response.data[1].name, response.data[2].name]
      
      } else {
        fuels = fuelsSelected?.map((fuel) => {
         return fuel.label
        })
      }
    }
    
    //SALVA NO STORAGE A LISTAGEM DE COMBUSTÍVEIS 
    localStorage.setItem("fuelsStorage",JSON.stringify(fuels));
    // localStorage.removeItem("fuelsStorage")
    
    setColumns(fuels.length + 1);
    setFuelsColumns(fuels);
    
    // INICIANDO LISTAGEM COM TODA EXIBIÇÃO = FALSE
    const formattedList = listEstablishments?.map((establishments) => {
      establishments.showPrice = false;
      if (establishments.ignoreSelection) {
      establishments.selected = false;
      }
      establishments.showPriceBrand = true;
      establishments?.valores?.forEach((valor) => {
        valor.show = false;
      })
      return establishments
    })
    
    // EXIBINDO OS VALORES QUE FORAM SELECIONADOS (COMBUSTÍVEIS)
    const newList = formattedList?.map((establishments) => {
      var quantity = 0;
      establishments?.valores?.forEach((valor) => {
        fuels?.forEach((fuel) => {
          if (valor?.nome === fuel){
            valor.show = true;
          }
        })
        
        if (valor.show === true && valor.preço !== "0.000") {
          quantity = quantity + 1;
        }
      })
      
      if (establishments.show === true) {
        if (quantity >= 1 ){
          establishments.showPrice = true;
        } else {
          establishments.showPriceBrand = false;
        }
      }
      
      return establishments
    })
    
    // FILTRANDO PELO LABEL DE CIDADE/ENDEREÇO/POSTO
    if (filter !== ""){
      const newListFilter = newList?.map((establishments) => {
        var name = removeAcento(establishments.nome.toUpperCase());
        var state = removeAcento(establishments.estado.toUpperCase());
        var input = removeAcento(filter.toUpperCase());
        
        if (name.indexOf(input) === -1 && state.indexOf(input) === -1){
          establishments.showPrice = false;
          establishments.showPriceBrand = false;
        } 
        return establishments
      })
      
      setListEstablishments(newListFilter);
      
    } else {
      setListEstablishments(newList);
    }
    
    localStorage.setItem("foundStorage", handleFound());
    
    }, [fuelsSelected, listEstablishments, filter, setListEstablishments, handleShowFuel, handleFound]
  );
  

  
  function selectedCount() {
    var count = 0
    listEstablishments?.forEach((establishments) => {
      if (establishments.selected) {
        count = count + 1
      }
    })
    return count
  }
  
  const handleSaveRoute = useCallback(async() => {
    if (!nameRoute ) {
      return toast.error("Preencha o nome da rota.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored"
      });
    }
    
    var listSelected = []
    listEstablishments?.forEach((establishment) => {
      if (establishment.selected) {
        listSelected.push(establishment.id)
      }
    })
    var listSelectedString = listSelected.toString();
    
    try {
      await api.post(`/routes`, {
        alias: nameRoute,
        type: search[1],
        radius: search[2],
        tolls: search[3],
        ferries: search[4],
        highways: search[5],
        address_1: search[6],
        address_2: search[7][0]?.local,
        address_3: search[7][1]?.local,
        address_4: search[7][2]?.local,
        address_5: search[7][3]?.local,
        address_6: search[7][4]?.local,
        address_7: search[7][5]?.local,
        address_8: search[7][6]?.local,
        address_9: search[7][7]?.local,
        address_10: search[7][8]?.local,
        merchants: listSelectedString
      });
      
      setNameRoute("");
      
      toast.success("Rota salva com sucesso.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored"
      });
      
      try {
      
        const { data: response } = await api.get(`routes`);
        
        const formattedRoutes = response.data.map((route) => {
          const obj = {};
          Object.assign(obj, {
              name: route.alias,
              id: route.id,
              initial: route.address_1,
              adresses: [
                route.address_2 && route.address_2,
                route.address_3 && route.address_3,
                route.address_4 && route.address_4,
                route.address_5 && route.address_5,
                route.address_6 && route.address_6,
                route.address_7 && route.address_7,
                route.address_8 && route.address_8,
                route.address_9 && route.address_9,
                route.address_10 && route.address_10,
              ],
              typeRoute: route.type ? route.type : "",
              radius: route.radius,
              tolls: route.tolls === "0" ? false : true,
              ferries: route.ferries === "0" ? false : true,
              highways: route.highways === "0" ? false : true,
          });
  
          return obj;
        });
  
        setRoutes(formattedRoutes)
      } catch (error) {
        console.log(error)
      }
     
    } catch (error) {
      console.log(error)
    }
  }, [nameRoute, listEstablishments, search, setRoutes])
  
  function removeAcento (text){       
    text = text.toLowerCase();                                                         
    text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
    text = text.replace(new RegExp('[Ç]','gi'), 'c');
    return text;                 
  }
  
  useEffect(() => {
    setLoading(true);
    loadFuels();
    handleFilter();
    setModalEstabelecimentos(modalEstabelecimentos);
    setLoading(false); 
    setRoutes(routes);
  }, [routes, setRoutes, modalEstabelecimentos, setModalEstabelecimentos]); // eslint-disable-line
  
  const handleChangeCheckbox = useCallback((id) => {
    var count = 0;
    const newList = listEstablishments?.map((establishment) => {
      if (establishment.id === id){
        establishment.selected = !establishment.selected;
      }
      
      if (establishment.selected) { 
        count = count + 1
      }
      
      return establishment
    })
    
    setListEstablishments(newList)
  }, [listEstablishments, setListEstablishments]);
  
  const handleChangeAllCheckbox = useCallback((e) => {
    setAllChecked(!allChecked)
    var count = 0;
    const newList = listEstablishments?.map((establishment) => {
    
      if (establishment.showPrice && establishment.brandSelect && establishment.showAllFavorites) {
        establishment.selected = !allChecked;
      }
      
      if (establishment.selected) { 
        count = count + 1
      }
      
      return establishment
    })
    
    setListEstablishments(newList)
  }, [listEstablishments, setListEstablishments]);
  
  const handlePrint = useCallback(async () => {
    setLoadingButton(true);
    var listPrint = [];
    var listDistance = [];
    listEstablishments?.forEach((establishment) => {
      if (establishment.selected) {
        listPrint.push(establishment.id);
        var distanceString = (establishment.distance).toString();
        distanceString = distanceString.replaceAll(",", ".");
        listDistance.push(distanceString)
      }
    })
    var fuels = [];
    var fuelsStorage = localStorage.getItem("fuelsStorage");

    if (fuelsSelected.length < 1){
      if (fuelsStorage) {
        fuelsStorage = JSON.parse(fuelsStorage);

         fuelsStorage.map((fuelStorage) => {
          var selected = optionsFuels.filter(
            (fuel) => fuel.label === fuelStorage
          );

          return fuels.push(selected?.[0]?.value)
        });
        
      } else {
        fuels = [10, 20, 30]
        
      }
    } else {
      
      fuels = fuelsSelected?.map((fuel) => {
       return fuel.value
      })
    }
    
    if (listEstablishments[0]?.valores[indexValue]?.id){
      fuels.push(listEstablishments[0]?.valores[indexValue]?.id)
    }
    
    var listPrintString = listPrint.toString();
    var fuelsString = fuels.toString();
    var listDistanceString = listDistance.toString();
    var listDistanceFormatted = listDistanceString.replaceAll(",", "|");
    listDistanceFormatted = listDistanceFormatted.replaceAll(".", ",");
    
    try {
      const download = await api.get(
        `/merchants/download?ids=${listPrintString}&fuels=${fuelsString}&distances=${listDistanceFormatted}`,
        {
          responseType: "blob",
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([download.data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "Lista Estabelecimentos.pdf"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();

      setLoadingButton(false);
      
    } catch (error) {
      setLoadingButton(false);
      console.log(error);
    }    
    
  }, [indexValue, optionsFuels, fuelsSelected, listEstablishments]);
  
  function mtel(v){
    v=v.replace(/\D/g,"");             
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); 
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");
    return v;
  } 
  
  
  return (
    <>
      <Modal columns={columns}>
        <ReactModal 
          initHeight={560} 
          isOpen={() => setModalEstabelecimentos(true)}
          disableResize={true}
          top={60}
          left={'auto'}
         >
          <ContentEstabelecimento columns={columns}>
            <header>
             {/* <MdAddBusiness /> */}
             <img src={iconLista} alt="iconLista" />
             <p><FormattedMessage id="listaEstabelecimentos" /></p> 
             <FaTimes onClick={() => setModalEstabelecimentos(false)} className="close"/>
            </header>
            
            <nav>
              <span>
                 <Multiselect
                   options={optionsFuels} 
                   selectedValues={optionsFuels.selectedValue} 
                   onSelect={onSelect}
                   onRemove={onRemove} 
                   displayValue="label" 
                   selectionLimit={3}
                   placeholder={selectPlaceholder}
                   hidePlaceholder={true}
                   closeOnSelect={true}
                   closeIcon={"close"}
                   className={fuelsSelected.length > 0 ? "hideInput" : "showInput"}
                   disablePreSelectedValues={true}
                 />
               </span>
            </nav>
            
            <nav style={{ display: 'grid', gridTemplateColumns: '70% 28%', gap: '2%'}}>
             <InputText
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                placeholder={optionsPlaceholder}
                type="text"
              />
             <Button onClick={() => handleFilter('filter')}><FormattedMessage id="filtrar" /></Button>
            </nav>
           
            
            <div>
            <section>
             <p> <b>{handleFound()} </b><FormattedMessage id="estabelecimentosEncontrados" /></p>
             <li style={{justifyItems: 'center'}}>
              {fuelsColumns[0] && (
                <ul>{fuelsColumns[0]}</ul>
              )}
              {fuelsColumns[1] && (
                <ul>{fuelsColumns[1]}</ul>
              )}
              {fuelsColumns[2] && (
                <ul>{fuelsColumns[2]}</ul>
              )}
              
               <ul data-for='fuel' data-tip='fuel' style={{textTransform: "uppercase"}}>
                {listEstablishments[0]?.valores[indexValue]?.nome ? listEstablishments[0]?.valores[indexValue]?.nome : <FormattedMessage id="selecione" />}
                </ul>  
                
                <ul>
                  {/* <p>Todos</p>
                  <br/> */}
                  
                  <Checkbox all={true} data-for='checked' data-tip='checked'>
                    <label>
                      <InputText 
                        type="checkbox" 
                        onChange={(e) => handleChangeAllCheckbox(e)}
                        checked={allChecked}
                        // disabled={modalImprimir ? true : false}
                      />
                      <span></span>
                    </label>
                  </Checkbox>
                </ul>
                
                <Tooltip 
                  id={'checked'}
                  text={<h3><FormattedMessage id="Selecione tudo" /></h3>}
                  place={'top'} 
                  color={'#FF7375'}
                />
                
               <Tooltip
                id={'fuel'}
                color={'#221C46'}
                place={'bottom'} 
                text={
                  <div >
                    <h2>Selecione um combustível</h2>
                    
                    {optionsFuels?.map((fuel, index) => (
                     <main key={index} onClick={() => handleShowFuel(fuel)}>
                       <img src={iconBomba} alt={'image'+index}/>
                       <span>{fuel?.label}</span>
                     </main>
                    ))}
                  </div>
                }
              />
             </li>
            </section>
            
            {loading ? (
              <LoaderContent />
            ) : (
              <div className="container"> 
              {listEstablishments?.map((establishments, indexEstablishments) => (
                (establishments.showPrice && establishments.brandSelect && establishments.showAllFavorites)  &&  (
                  <main key={indexEstablishments}>
                   {/* {onIconCommerce(comercio?.tipo)} */}
                     <img alt="icon" src={establishments?.icon}/>
                     <h1>
                        <span>{establishments?.nome}</span> <br/>
                        {establishments?.endereço} <br/>
                        {establishments?.estado}
                        <footer>{establishments?.distance}</footer>
                     </h1> 
                     {establishments?.valores?.map((valor, indexValor) => (
                      valor.show && (
                          valor.preço !== "0.000" ? (
                            <div key={indexValor}>
                             <h4>R${valor.preço}</h4>
                             <h5>* {format(new Date(valor.data), 'dd/MM/yy')}</h5>
                            </div>
                            
                          ) : (
                            <div key={indexValor}> - </div>
                          )
                      )
                     ))} 
                     {establishments?.valores[indexValue]?.preço ? (
                        <div>
                         <h4>R${establishments?.valores[indexValue]?.preço}</h4>
                         <h5>* {format(new Date(establishments.valores[indexValue]?.data), 'dd/MM/yy')}</h5>
                        </div> 
                     ): (
                        <div> - </div>
                     )}  
                     
                     <Checkbox>
                        <label>
                          <InputText 
                            type="checkbox" 
                            onChange={() => handleChangeCheckbox(establishments.id)}
                            checked={establishments.selected}
                            disabled={modalImprimir ? true : false}
                          />
                          <span></span>
                        </label>
                    </Checkbox>
                  </main>
                )
              ))}
            </div>
            )}
            </div>
            
            < hr />
          
            <h6><FormattedMessage id="valoresReferencia" /> <br/><FormattedMessage id="dataAtualização" /> ( * )</h6>
            <h6><b>Distância total da rota:<br/> {search[8]} km</b></h6>
            <Button 
              onClick={() => setModalImprimir(true)}
              disabled={selectedCount() <= 0 ? true : false}
            >
                <FormattedMessage id="preImpressao" />
            </Button>
            
            {search[0] === 'rota' && (
               <Button data-for='saveRoute' data-tip='saveRoute' onClick={() => handleSaveRoute()}><FormattedMessage id="salvarRota" /></Button>
            )}

          </ContentEstabelecimento>
        </ReactModal>
        <Tooltip
        id={'saveRoute'}
        color={'#242b30'}
        place={'bottom'} 
        className={'tooltipRoute'}
        event={'click'}
        text={
          <div>
            <InputText
              type="text"
              placeholder={nameRoutePlaceholder} 
              value={nameRoute}
              onChange={(e) => setNameRoute(e.target.value)}
            /> 
            <Button onClick={() => handleSaveRoute()}><FormattedMessage id="salvar" /></Button>
          </div>
        }
      />
      </Modal>
      
   
      
      {modalImprimir && (
        <Modal columns={columns}>
          <ReactModal 
            initHeight={560} 
            isOpen={() => setModalImprimir(true)}
            disableResize={true}
            top={60}
            left={'auto'}
           >
            <ContentEstabelecimento columns={columns} type={"print"}>
              <header>
               <MdAddBusiness />
               <p><FormattedMessage id="selecionadosImpressao" /></p> 
               <FaTimes onClick={() => setModalImprimir(false)} className="close"/>
              </header>
              
              
              <section>
             <p> <b>{selectedCount()} </b><FormattedMessage id="estabelecimentosSelecionados" /></p>
             <li>
              {fuelsColumns[0] && (
                <ul>{fuelsColumns[0]}</ul>
              )}
              {fuelsColumns[1] && (
                <ul>{fuelsColumns[1]}</ul>
              )}
              {fuelsColumns[2] && (
                <ul>{fuelsColumns[2]}</ul>
              )}
              <ul data-for='fuel' data-tip='fuel' style={{textTransform: "uppercase"}}>
                {listEstablishments[0]?.valores[indexValue]?.nome ? listEstablishments[0]?.valores[indexValue]?.nome : <FormattedMessage id="selecione" />}
                </ul>  
 
  
             </li>
            </section>
              
              {loading ? (
                <LoaderContent />
              ) : (
                <div className="container" style={{height: 355}}>
                {listEstablishments?.map((establishments, indexEstablishments) => (
                  establishments.selected  &&  (
                    <main key={indexEstablishments}>
                       <img alt="icon" src={establishments?.icon}/>
                       <h1>
                          <span>{establishments?.nome}</span> <br/>
                          {establishments?.endereço} <br/>
                          {establishments?.estado} <br/>
                          {mtel(establishments?.phone)}
                          <footer>{establishments?.distance}</footer>
                       </h1> 
                       {establishments?.valores?.map((valor, indexValor) => (
                        valor.show && (
                            valor.preço !== "0.000" ? (
                              <div key={indexValor}>
                               <h4>R${valor.preço}</h4>
                               <h5>* {format(new Date(valor.data), 'dd/MM/yy')}</h5>
                              </div>
                              
                            ) : (
                              <div key={indexValor}> - </div>
                            )
                        )
                       ))} 
                        {establishments?.valores[indexValue]?.preço  ? (
                          <div>
                           <h4>R${establishments?.valores[indexValue]?.preço}</h4>
                           <h5>* {format(new Date(establishments.valores[indexValue]?.data), 'dd/MM/yy')}</h5>
                          </div> 
                       ): (
                          <div> - </div>
                       )}  
                    </main>
                  )
                ))}
              </div>
              )}
              < hr />
              <Button 
                onClick={() => handlePrint()}
                disabled={selectedCount() <= 0 ? true : false}
              >
               { loadingButton ? <Spinner /> : <FormattedMessage id="imprimirLista" />}
                
              </Button>
              
            </ContentEstabelecimento>
          </ReactModal>
        </Modal>
      )}
    </>
  );
};

export default injectIntl(ModalPrint);