import { datadogRum } from '@datadog/browser-rum';
const env = process.env.REACT_APP_ENV;
const datadogInit = () =>{

    if (env === 'development' || env === 'qa') {
        return;
    }
    datadogRum.init({
        applicationId: 'edd2704d-205d-43de-9ee6-013b27ed725a',
        clientToken: 'pubb25728c6d4c68f0880a0fe72b8e04302',
        site: 'datadoghq.com',
        service: 'route',
        env: 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
};

const dataDogUser = (email)=>{
    datadogRum.setUser({ email: email});
}

export {
    datadogInit,
    dataDogUser
};
